import request from "@/utils/request";

export function list(params) {
  return request({
    url: "api/proRisk",
    method: "GET",
    params,
  });
}
export function getDictDetail(params) {
  return request({
    url: "api/dictDetail",
    method: "get",
    params,
  });
}
export function getById(params) {
  return request({
    url: `api/proRisk/${params}`,
    method: "GET",
  });
}


export default {
  list,getDictDetail,getById
};
