export const expEnum = {
    DOING: {id: 0, key: "DOING", name: "进行中", type: "primary"},
    FINISH : {id: 1, key: "FINISH ", name: "已完成",type: "success"},
  };
  export const riskStatusEnum = {
    OPEN: {id: 0, key: "OPEN", name: "发生中", type: "danger"},
    DEAL: {id: 1, key: "DEAL", name: "处置中", type: "primary"},
    CLOSE: {id: 2, key: "CLOSE ", name: "已解决", type: "success "},
  };
export default {
    expEnum,
    riskStatusEnum
}