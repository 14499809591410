import request from "@/utils/request"
export function getStage(params) {
    return request({
        url: "api/proStage/list",
        method: "get",
        params,
    });
}

export function getStageById(id) {
    return request({
        url: "api/proStage/" + id,
        method: "get",
    });
}