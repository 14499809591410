<template>
  <div class="app v">
    <div class="padding-08 no-flex">
      <div class="padding-08 fc-t bold">
        <span>
          <template v-if="task">{{task.proName}}&nbsp;/&nbsp;{{task.stageName}}&nbsp;/&nbsp;{{task.name}}</template>
          <template v-else-if="stage">{{stage.proName}}&nbsp;/&nbsp;{{stage.name}}</template>
          <template v-else-if="project">{{project.proName}}</template>
        </span>
        <br />
        <span class="fs-huge">风险记录列表</span>
      </div>
    </div>

    <van-pull-refresh v-model="refreshing" class="flex" style="overflow: hidden auto;" @refresh="reload">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" @load="load">
        <risk-item v-for="item in store" :risk="item" :levels="riskLevels" :key="item.id" />
        <van-empty v-if="store.length == 0" description="暂无风险记录" />
      </van-list>
    </van-pull-refresh>
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh" finished-text="没有更多了" class="container">
      <van-list v-model="loading" :finished="finished" :finished-text="listData.length==0?'':'没有更多了'" @load="getList()">
        <div class="demo-project flex">
          <div v-for="item in listData" class="item gap-f-small" :key="item.id" @click="goRiskDetail(item)">
            <div class="header-risk padding-05">
              <div class="h sb c">
                <div class="h c sb">
                  <div class="text-no-wrap padding-05 name">{{item.begTime}}，{{item.createBy}}</div>
                </div>
                <van-tag :type="riskstatus[item.status]?.type">{{ riskstatus[item.status].name }}</van-tag>
              </div>
            </div>
            <div class="h sb c content padding-10">
              <div class="gap-small gap-b-1x fs-small fc-c content">
                {{ item
                .remark }}
              </div>
            </div>
          </div>
        </div>
        <van-empty v-if="listData.length == 0" :description="this.projectId?'无项目风险数据':'无任务风险数据'" />
      </van-list>
    </van-pull-refresh>-->
  </div>
</template>
  
  <script>
import { list, getDictDetail } from "@/api/project/risk";
import { getTaskById } from "@/api/project/task";
import { getStageById } from "@/api/project/stage";
import { getProjectById } from "@/api/project/index";
import RiskItem from "./item";

export default {
  name: "ProjectProgress",
  components: { RiskItem },
  data() {
    return {
      page: 1,
      size: 20,
      total: 0,
      store: [],
      refreshing: false,
      loading: false,
      finished: false,
      task: null,
      stage: null,
      project: null,
      riskLevels: [],
    };
  },
  computed: {
    params() {
      return this.$route.params;
    },
  },
  watch: {
    params: {
      handler: function () {
        this.reload();
        if (this.params.taskId) this.getTask();
        else if (this.params.stageId) this.getStage();
        else if (this.params.projectId) this.getProject();
      },
      immediate: true,
    },
  },
  methods: {
    load() {
      list({
        current: this.page,
        size: this.size,
        // orders: [{ column: "recTime", asc: false }],
        ...this.$route.params,
      })
        .then((res) => {
          if (res) {
            this.store.push(...res.content);
            this.total = res.totalElements;
            this.finished = this.store.length >= this.total;
            this.page++;
          }
        })
        .finally(() => {
          this.loading = false;
          this.refreshing = false;
        });
    },
    reload() {
      this.page = 1;
      this.store = [];
      this.load();
    },
    getTask() {
      getTaskById(this.params.taskId).then((res) => {
        this.task = res;
      });
    },
    getStage() {
      getStageById(this.params.stageId).then((res) => {
        this.stage = res;
      });
    },
    getProject() {
      getProjectById(this.params.projectId).then((res) => {
        this.project = res;
      });
    },
    loadDicts() {
      getDictDetail({
        dictName: "risk_level",
        page: 0,
        size: 9999,
      }).then((res) => {
        this.riskLevels = res.content;
      });
    },
  },
  mounted() {
    this.loadDicts();
  },
};
</script>