<template>
  <van-cell is-link :to="`/project/risk/detail/${risk.id}`" v-if="risk">
    <div class="h c" style="height: 24px;" slot="title">
      <van-tag type="danger">{{levelName}}</van-tag>
      <van-tag type="default" class="gap-l-10">{{risk.riskTypeName}}</van-tag>
      <div class="flex"></div>
      <van-tag :type="status[risk.status].type">{{status[risk.status].name}}</van-tag>
    </div>
    <template #label>
      <div class="gap-b-10 fs-normal fc-t van-multi-ellipsis--l2">{{risk.remark}}</div>
      <div>由&nbsp;{{risk.createBy}}&nbsp;于&nbsp;{{risk.begTime}}&nbsp;创建</div>
      <div class="fc-s" v-if="risk.status === 'CLOSE'">由&nbsp;{{risk.dealUserName}}&nbsp;于&nbsp;{{risk.endTime}}&nbsp;处置</div>
    </template>
  </van-cell>
</template>

<script>
import { riskStatusEnum } from "@/enums/index";

export default {
  props: {
    risk: Object,
    levels: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      status: riskStatusEnum,
    };
  },
  computed: {
    levelName() {
      if (this.risk && this.levels && this.levels.length) {
        const l = this.levels.find((o) => o.id === this.risk.riskLeve);
        if (l) return l.label;
      }
      return null;
    },
  },
};
</script>