import request from "@/utils/request"
export function getProjectList(params) {
    return request({
        url: 'api/proProject',
        method: 'get',
        params
    })
}
export function getProjectById(id) {
    return request({
        url: 'api/proProject/' + id,
        method: 'get',
    })
}
export function getTaskMembers(params) {
    return request({
        url: "api/proTaskMember",
        method: "get",
        params,
    });
}
export function getProjectMembers(params) {
    return request({
        url: "api/projectMember",
        method: "get",
        params,
    });
}