import request from "@/utils/request"
export function getTask(params) {
    return request({
        url: "api/proTask/list",
        method: "get",
        params,
    });
}
export function getTaskById(id) {
    return request({
        url: "api/proTask/" + id,
        method: "get",
    });
}